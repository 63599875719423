import React from 'react';

import {
  AngleLeftLightIcon,
  HouseSolidIcon
} from '../../../../shared/icons/icons';
import Link from '../../../link/link';
import Typography from '../../../typography/typography';

import { useMediaQuery } from '../../../../shared/hooks/hooks';

import { isArrayWithLength } from '../../../../shared/utils/validators/validators';

import './breadcrumbs.scss';

interface BreadcrumbsProps {
  className?: string;
  crumbs: {
    name: string;
    url?: string;
  }[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { className, crumbs } = props;
  const { isMinSm } = useMediaQuery();

  return isArrayWithLength(crumbs) ? (
    <div className={`${className || ''} breadcrumbs`} styleName="container">
      <ol className="breadcrumbs__list" styleName="crumbs">
        {crumbs.map((crumb, idx) => (
          <li
            className="breadcrumbs__list-item"
            key={`bc-${idx}`}
            styleName="crumb"
          >
            {crumb.url ? (
              <Link
                className="dt-link breadcrumbs__list-item-link"
                styleName="crumb-link"
                to={crumb.url}
              >
                <AngleLeftLightIcon
                  className="breadcrumbs__list-item-link-icon"
                  styleName="crumb-arrow"
                />
                {!isMinSm && crumb.url === '/' ? (
                  <HouseSolidIcon />
                ) : (
                  <Typography tag="span" weight="heavy">
                    {crumb.name}
                  </Typography>
                )}
              </Link>
            ) : (
              <div styleName="crumb-text">
                <AngleLeftLightIcon styleName="crumb-arrow" />
                <Typography tag="span" weight="heavy">
                  {crumb.name}
                </Typography>
              </div>
            )}
          </li>
        ))}
      </ol>
    </div>
  ) : null;
}

export default Breadcrumbs;
