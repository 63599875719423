import { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cookies from 'js-cookie';

import { MyAccountContext } from '../../contexts';
import { useFitment, useTreadwellPreferences } from '../hooks';

import { trackView } from '../../utils/analytics/analytics';
import { getVehicleDataLayerValues } from '../../utils/fitment/fitment';
import { localStorage as ls } from '../../utils/storage/storage';
import { getStoreDataLayerValues } from '../../utils/store/store';
import { getTreadwellVersion } from '../../utils/treadwell/treadwell';

import { AFFILIATE, USABLE_NET, UTAG } from '../../constants/cookie-names';
import {
  AFFILIATE_ID,
  DAYS_SINCE_LAST_VISIT,
  DOCUMENT_REFERRER,
  DOCUMENT_REFERRERS,
  ENVIRONMENT,
  HAS_VEHICLE,
  IDME_GROUP,
  IS_AUTHENTICATED,
  IS_CUSTOMER,
  IS_IDME_AUTHENTICATED,
  IS_PDL,
  IS_POS,
  NUMBER_OF_VISITS,
  PAGE_HOST,
  PAGE_PATH,
  PAGE_TYPE,
  PAGE_URL,
  PAGE_VIEWABLE_PERCENTAGE,
  PREV_PAGE_VIEWED_PERCENTAGE,
  PRODUCT_LIST_SORT,
  SITE_VERSION,
  TEST_NAMES,
  TEST_VARIANTS,
  TEST_VERSION,
  TREADWELL_DRIVERTYPE,
  TREADWELL_DRIVERTYPE_CUSTOMIZED_PRIORITIES,
  TREADWELL_DRIVERTYPE_OPTION_OFFROAD,
  TREADWELL_DRIVERTYPE_PRIORITIES,
  TREADWELL_VERSION,
  USEABLENET_ACCESSIBILITY_IS_ON,
  USER_ID
} from '../../constants/data-layer';
import { SORT_PARAMS } from '../../constants/search-types';
import {
  DAYS_SINCE_LAST_VISIT as DAYS_BETWEEN_LAST_VISIT,
  IDME_IS_VERIFIED,
  IDME_VERIFIED_GROUP,
  LAST_VISIT,
  MY_STORE,
  REFERRERS,
  TREADWELL_IS_IN_FLOW
} from '../../constants/storage-keys';

const SITE_ENVIRONMENTS = {
  dev: 'development',
  prd: 'production',
  qa: 'qa',
  stg: 'stg'
};

const dtAtRegEx =
  /discounttire|americastire|discounttiredirect|dtui|dtdev|dtqa/;

function initializeDataLayer() {
  if (!window.__DATA_LAYER__) {
    window.__DATA_LAYER__ = {
      history: [],
      page: null,
      session: null
    };
  }
}

function getUtagInfo() {
  let utagInfo = cookies.get(UTAG) || {};

  if (typeof utagInfo === 'string') {
    utagInfo = utagInfo
      .replace(/;exp-session/g, '')
      .split('$')
      .reduce((acc, v) => {
        const [key, value] = v.split(':');
        acc[key] = value;
        return acc;
      }, {});
  }

  return utagInfo;
}

function getReferrers() {
  const documentReferrer = document.referrer;
  const referrers = new Set(ls(REFERRERS) || []);

  if (!dtAtRegEx.test(documentReferrer) || !referrers.has(documentReferrer)) {
    referrers.add(documentReferrer);
    ls(REFERRERS, [...referrers]);
  }

  return [...referrers];
}

function getDaysSinceLastVisit(visitTimeStamp) {
  const lastVisitTimeStamp = parseInt(ls(LAST_VISIT));

  if (window.__DATA_LAYER__?.history?.length === 0) {
    const currentTime = new Date().getTime();
    const msInDay = 86400000;
    const diff = currentTime - lastVisitTimeStamp || 0;
    const daysSinceLastVisit = diff / msInDay;

    ls(LAST_VISIT, visitTimeStamp);
    ls(DAYS_BETWEEN_LAST_VISIT, daysSinceLastVisit.toFixed(2));
  }

  return ls(DAYS_BETWEEN_LAST_VISIT);
}

function updateSessionDataLayerValues() {
  const { _sn: sessionCount, ses_id: visitTimeStamp } = getUtagInfo();

  if (!window.__DATA_LAYER__.session) {
    window.__DATA_LAYER__.session = {
      [AFFILIATE_ID]: cookies.get(AFFILIATE) || null,
      [DAYS_SINCE_LAST_VISIT]: getDaysSinceLastVisit(parseInt(visitTimeStamp)),
      [DOCUMENT_REFERRERS]: getReferrers(),
      [ENVIRONMENT]: SITE_ENVIRONMENTS[process.env.__SITE_ENV__] || '',
      [IS_CUSTOMER]: '1',
      [IS_POS]: '0',
      [NUMBER_OF_VISITS]: sessionCount || null,
      [SITE_VERSION]: process.env.__APP_VERSION__,
      [TEST_NAMES]: [],
      [TEST_VARIANTS]: [],
      [TEST_VERSION]: ''
    };
  }
}

function updatePageDataLayerValues(pageValues, shouldCreateNewEntry = true) {
  if (typeof pageValues === 'object') {
    if (shouldCreateNewEntry && window.__DATA_LAYER__.page) {
      const history = [...window.__DATA_LAYER__.history];

      history.push(window.__DATA_LAYER__.page);
      window.__DATA_LAYER__.history = history.slice(-19);
    }

    if (!shouldCreateNewEntry) {
      window.__DATA_LAYER__.page = {
        ...window.__DATA_LAYER__.page,
        ...pageValues
      };
    } else {
      window.__DATA_LAYER__.page = pageValues;
    }
  }
}

let previousPageUrl;

function useDataLayer({ shouldResetPageOnFitmentChange = false } = {}) {
  const { pathname, search } = useLocation();
  const { selectedFitment, partialFitment } = useFitment();
  const { isLoggedIn, user } = useContext(MyAccountContext);
  const { treadwellPreferences } = useTreadwellPreferences();
  const pageUrl = `${pathname}${search}`;
  const userId = user?.customerId || '';

  const updateDataLayer = useCallback(
    (pageValues, shouldCreateNewEntry = true) => {
      const hasTrackedPage = previousPageUrl === pageUrl;
      const currentSort = pageValues?.[PRODUCT_LIST_SORT];
      const isTreadwellRecommended =
        ls(TREADWELL_IS_IN_FLOW) &&
        (currentSort === SORT_PARAMS.RECOMMENDED || !currentSort);

      if (hasTrackedPage && shouldCreateNewEntry) return;

      const hasNavigatedFromWithinApp = Boolean(window.__DATA_LAYER__.page);
      const isPDL = isTreadwellRecommended ? '1' : '0';
      const isAccessibilityModeOn = cookies.get(USABLE_NET) ? '1' : '0';
      let hasVehicle = '0';

      if (selectedFitment) {
        hasVehicle = '1';
      } else if (partialFitment) {
        hasVehicle = '2';
      }

      let defaultPageValues = {
        [DOCUMENT_REFERRER]:
          hasNavigatedFromWithinApp || dtAtRegEx.test(document.referrer)
            ? 'Non-Referred Page'
            : document.referrer,
        [HAS_VEHICLE]: hasVehicle,
        [IDME_GROUP]: ls(IDME_VERIFIED_GROUP),
        [IS_AUTHENTICATED]: `${isLoggedIn}`,
        [IS_IDME_AUTHENTICATED]: ls(IDME_IS_VERIFIED) ? '1' : '0',
        [IS_PDL]: isPDL,
        [PAGE_HOST]: window.location.hostname,
        [PAGE_PATH]: window.location.pathname,
        [PAGE_URL]: window.location.href,
        [PAGE_VIEWABLE_PERCENTAGE]: `${Math.round(
          (window.innerHeight / document.getElementById('root')?.offsetHeight) *
            100
        )}%`,
        [USEABLENET_ACCESSIBILITY_IS_ON]: isAccessibilityModeOn
      };

      if (shouldCreateNewEntry) {
        const myStore = ls(MY_STORE);

        if (isLoggedIn && userId) {
          defaultPageValues[USER_ID] = userId;
        }

        if (window.prevPageViewedPercentage) {
          defaultPageValues[
            PREV_PAGE_VIEWED_PERCENTAGE
          ] = `${window.prevPageViewedPercentage}`;
        }

        if (myStore) {
          defaultPageValues = {
            ...defaultPageValues,
            ...getStoreDataLayerValues(myStore)
          };
        }

        if (selectedFitment || partialFitment) {
          defaultPageValues = {
            ...defaultPageValues,
            ...getVehicleDataLayerValues(selectedFitment || partialFitment)
          };
        }

        if (isPDL === '1') {
          defaultPageValues[TREADWELL_VERSION] = getTreadwellVersion;

          const isCustom = treadwellPreferences?.isCustomDriverTypeShowing;
          const customPrioritiesArr =
            treadwellPreferences?.selectedCustomDrivingPriorities || [];
          const personaPrioritiesArr =
            treadwellPreferences?.selectedDriverType?.tooltips || [];
          const priorities = isCustom
            ? customPrioritiesArr.map(p => p.id || '')
            : personaPrioritiesArr.map(t => t.id || '');

          defaultPageValues[TREADWELL_DRIVERTYPE] = isCustom
            ? treadwellPreferences?.selectedCustomDrivingPrioritiesType
            : treadwellPreferences?.selectedDriverType?.name;
          defaultPageValues[TREADWELL_DRIVERTYPE_PRIORITIES] = priorities;
          defaultPageValues[TREADWELL_DRIVERTYPE_CUSTOMIZED_PRIORITIES] =
            isCustom;
          defaultPageValues[TREADWELL_DRIVERTYPE_OPTION_OFFROAD] =
            treadwellPreferences?.showOffRoadTires ? '1' : '0';
        }

        updateSessionDataLayerValues();
      }

      updatePageDataLayerValues(
        {
          ...defaultPageValues,
          ...pageValues
        },
        shouldCreateNewEntry
      );

      window.utag_data = {
        tealium_account: 'discounttire',
        ...(window.__DATA_LAYER__.session || {}),
        ...(window.__DATA_LAYER__.page || {})
      };

      const viewData = window.utag_data;

      if (window.__DATA_LAYER__.history.length < 1) {
        viewData.timing_event = 'asap_load';
      }

      trackView(viewData);

      previousPageUrl = pageUrl;
    },
    [
      pageUrl,
      selectedFitment,
      partialFitment,
      isLoggedIn,
      userId,
      treadwellPreferences
    ]
  );

  useEffect(() => {
    if (shouldResetPageOnFitmentChange) {
      previousPageUrl = undefined;
    }
  }, [selectedFitment, shouldResetPageOnFitmentChange]);

  useEffect(() => {
    initializeDataLayer();
  }, []);

  return {
    currentPageType: window.__DATA_LAYER__?.page?.[PAGE_TYPE],
    updateDataLayer
  };
}

export default useDataLayer;
